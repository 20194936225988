'use strict'
/**
Django provides proper implementation of those methods.
For testing purposes we fake the correct implementation.
 */

const django = globalThis.django || (globalThis.django = {})

export const pluralidx = django.pluralidx || (msg => msg)
export const gettext = django.gettext || (msg => msg)
export const ngettext = django.ngettext || (msg => msg)
/* eslint-disable camelcase */
export const gettext_noop = django.gettext_noop || (msg => msg)
export const pgettext = django.pgettext || (msg => msg)
export const npgettext = django.npgettext || (msg => msg)
export const interpolate = django.interpolate || (msg => msg)
/* eslint-disable camelcase */
export const get_format = django.get_format || (msg => msg)
