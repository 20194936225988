import '#js/components/snackbar'
import { ready } from '#js/components/utils'

/* Update button state based on form input fields */
export function updateButtonState () {
  const emailInput = document.getElementById('emailInput')
  const emailFormInput = document.getElementById('id_email')
  const phoneInput = document.getElementById('phoneInput')
  const submitButton = document.getElementById('submitButton')

  if (submitButton && emailInput && phoneInput) {
    submitButton.disabled = emailInput.value.trim() === '' && phoneInput.value.trim() === ''
  }
  if (submitButton && emailFormInput) {
    submitButton.disabled = emailFormInput.value.trim() === ''
  }
}

/* Attach input listeners to email and phone inputs */
export function attachInputListeners () {
  const emailInput = document.getElementById('emailInput')
  const phoneInput = document.getElementById('phoneInput')
  const emailFormInput = document.getElementById('id_email')

  if (emailInput && phoneInput) {
    [emailInput, phoneInput].forEach(input => {
      input.addEventListener('input', updateButtonState)
    })
  }

  if (emailFormInput) {
    emailFormInput.addEventListener('input', updateButtonState)
  }
}

/* Prevent empty form submission */
export function preventEmptySubmit () {
  attachInputListeners()
  updateButtonState()
}

ready(function () {
  preventEmptySubmit()
})
